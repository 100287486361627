import "@/styles/ui_components/_tooltip.scss"

const TOOLTIP_SELECTOR = "[data-tooltip]"

class Tooltip {
  private static ACTIONS = Object.freeze({
    CLICK: "click",
    COPY: "copy",
    HOVER: "hover"
  } as const)
  private static ACTIVE_CLASS = "--active"
  private static TIMEOUT = 3000

  private action: (typeof Tooltip.ACTIONS)[keyof typeof Tooltip.ACTIONS]
  private element: HTMLElement
  private timeoutId?: number

  constructor(element: HTMLElement) {
    const { tooltip } = element.dataset

    this.action = (tooltip as typeof this.action) || Tooltip.ACTIONS.HOVER
    this.element = element

    switch (this.action) {
      case Tooltip.ACTIONS.CLICK:
        this.element.addEventListener("click", this.toggle.bind(this))
        break
      case Tooltip.ACTIONS.COPY:
        this.element.addEventListener("click", this.copy.bind(this))
        break
      case Tooltip.ACTIONS.HOVER:
        this.element.addEventListener("mouseover", this.show.bind(this))
        this.element.addEventListener("mouseout", this.hide.bind(this))
        this.element.addEventListener("touchstart", this.show.bind(this))
        this.element.addEventListener("touchend", this.hide.bind(this))
    }
  }

  copy(): void {
    const COPY_ATTR = "data-tooltip-copy"
    const text = this.element.getAttribute(COPY_ATTR) || this.element.textContent?.trim()

    if (!text) return

    void (async (): Promise<void> => {
      const MESSAGE_ATTR = "data-tooltip-message"
      const COPY_ICON = "fa-clipboard"
      const SUCCESS_ICON = "fa-check"
      const icon = this.element.querySelector(`i.${COPY_ICON}`)

      try {
        await window.navigator.clipboard.writeText(text)
        this.element.setAttribute(MESSAGE_ATTR, this.element.dataset.message || "Copied!")
        icon?.classList.replace(COPY_ICON, SUCCESS_ICON)
        this.show()

        window.setTimeout(() => {
          icon?.classList.replace(SUCCESS_ICON, COPY_ICON)
          this.hide()
        }, Tooltip.TIMEOUT)
      } catch {
        // Ignore copy error
      }
    })()
  }

  hide(): void {
    Tooltip.hideElement(this.element)
  }

  show(): void {
    window.clearTimeout(this.timeoutId)
    Tooltip.hideAll()
    this.element.classList.add(Tooltip.ACTIVE_CLASS)
    Tooltip.setPosition(this.element)

    if (this.action === Tooltip.ACTIONS.CLICK) {
      this.timeoutId = window.setTimeout(this.hide.bind(this), Tooltip.TIMEOUT)
    }
  }

  private static hideAll(): void {
    document.querySelectorAll<HTMLElement>(TOOLTIP_SELECTOR).forEach(Tooltip.hideElement)
  }

  private static hideElement(element: HTMLElement): void {
    element.classList.remove(Tooltip.ACTIVE_CLASS)
    Tooltip.setPosition(element, { reset: true })
  }

  private static setPosition(element: HTMLElement, { reset = false } = {}): void {
    if (reset) {
      element.style.setProperty("--x-offset", "0px")
      element.style.setProperty("--y-offset", "0px")
      return
    }

    const isPositionBottom = element.classList.contains("position:bottom")
    const tooltipStyles = window.getComputedStyle(element, "::before")
    const { bottom, height, right } = element.getBoundingClientRect()
    const tooltipRight = isPositionBottom
      ? right + parseFloat(tooltipStyles.width) / 2
      : right + parseFloat(tooltipStyles.left) + parseFloat(tooltipStyles.width)
    const tooltipBottom = isPositionBottom
      ? bottom + height / 2 + parseFloat(tooltipStyles.height)
      : bottom + parseFloat(tooltipStyles.height) / 2
    const { innerHeight, innerWidth } = window

    if (tooltipRight >= innerWidth) {
      element.style.setProperty("--x-offset", `${tooltipRight - innerWidth - 5}px`)
    }

    if (tooltipBottom >= innerHeight) {
      element.style.setProperty("--y-offset", `${tooltipBottom - innerHeight - 5}px`)
    }
  }

  private toggle(): void {
    if (this.element.classList.contains(Tooltip.ACTIVE_CLASS)) {
      this.hide()
    } else {
      this.show()
    }
  }
}

const setupTooltips = (container: Document | HTMLElement = document): void => {
  container.querySelectorAll<HTMLElement>(TOOLTIP_SELECTOR).forEach((tip) => new Tooltip(tip))
}

export { setupTooltips }
