import "@/styles/voucher-bookings.scss"
import "@hotwired/turbo"
import { application } from "./application"
import type { TurboSessionWithDrive } from "@/entry_points/types"

import SmoothScroll from "smoothscroll-polyfill"

import ElmModal from "@/components/elm_modal"
import Loader from "@/components/loader"
import "@/components/content/StatusBar"
import { setupAccordions } from "@/components/content/Accordion"
import { setupTooltips } from "@/components/content/Tooltip"
import { Elm } from "@/components/elm/VoucherBookings.elm"
import airbrakeClient, { logDecodingError } from "@/helpers/airbrake"
import { pushGa4Event } from "@/helpers/ga4_helper"
import { loadBackgroundImages, preloadImages } from "@/helpers/image_loader"
import Emarsys from "@/helpers/emarsys"
import getUserData from "@/helpers/user_data"

import CheckoutFormController from "../controllers/checkout/checkout_form_controller"
import DeliveryModalCarousel from "../controllers/checkout/voucher_bookings/delivery_modal_carousel_controller"
import DeliveryOptionsController from "../controllers/checkout/voucher_bookings/delivery_options_controller"
import DisclosureItemController from "../controllers/disclosure_item_controller"
import DiscountCodeController from "../controllers/checkout/discount_code_controller"
import EvoucherDeliveryController from "../controllers/checkout/voucher_bookings/evoucher_delivery_controller"
import GiftMessageFormController from "../controllers/checkout/gift_message_form_controller"
import GiftMessagePreviewController from "../controllers/checkout/gift_message_preview_controller"
import GiftMessageSectionController from "../controllers/checkout/gift_message_section_controller"
import LoaderController from "../controllers/loader_controller"
import ModalController from "../controllers/modal_controller"
import ModalTriggerController from "../controllers/modal_trigger_controller"
import CollapsibleItemsController from "../controllers/checkout/collapsible_items_controller"
import VoucherAmountController from "../controllers/checkout/voucher_bookings/voucher_amount_controller"
import VoucherPaymentSummaryController from "../controllers/checkout/voucher_bookings/payment_summary_controller"
import PhysicalDeliveryController from "../controllers/checkout/voucher_bookings/physical_delivery_controller"
import DeliveryOptionsModalController from "../controllers/checkout/voucher_bookings/delivery_options_modal_controller"
import QuantityCounterController from "../controllers/checkout/voucher_bookings/quantity_counter_controller"
import InputUtilsController from "../controllers/forms/input_utils_controller"
import InputValidityController from "../controllers/forms/input_validity_controller"
import ErrorTrackingController from "../controllers/tracking/error_tracking_controller"
import RedirectController from "../controllers/redirect_controller"
import WindowNavigationController from "../controllers/window_navigation_controller"

declare global {
  interface Window {
    elmFlags: Flags
    themeImages: Array<string>
  }
}

SmoothScroll.polyfill()

const initCheckout = async (): Promise<void> => {
  const node = document.getElementById("js-voucher-bookings")
  if (!node) return

  const loader = new Loader()
  loader.start()

  const CAROUSEL_IMG_SELECTOR = ".ui-component-carousel [data-img]"
  const modal = new ElmModal({ modalId: "elm-product-modal" })
  const flags = {
    ...window.elmFlags,
    userData: await getUserData()
  }
  const app = await Elm.VoucherBookings.init({ node, flags })

  loader.stop()

  app.ports.pageChange.subscribe(() => {
    document.body.scrollIntoView({ behavior: "smooth" })
  })

  app.ports.pushGa4Event.subscribe(pushGa4Event)

  app.ports.flagDecodingError.subscribe(() => logDecodingError(flags))

  app.ports.reportError.subscribe((err: string) => {
    void airbrakeClient.notify({ error: "Error in vochers Elm app", params: { err } })
  })

  app.ports.selectCounterValue.subscribe((e: Event) => {
    ;(e.target as HTMLInputElement)?.select()
  })

  app.ports.modalOpened.subscribe((isOpen: boolean) => {
    modal.trapFocus(isOpen)

    window.requestAnimationFrame(() => {
      loadBackgroundImages(document.querySelectorAll(CAROUSEL_IMG_SELECTOR))
    })
  })

  app.ports.carouselActivated.subscribe((carouselId: string) => {
    loadBackgroundImages(document.querySelectorAll(`#${carouselId} .js-img`))
  })

  app.ports.updateAmountParam.subscribe((amount: string) => {
    const url = new URL(window.location.href)
    const params = url.searchParams

    params.set("amount", amount)
    url.search = params.toString()
    window.history.replaceState({}, "", url.toString())
  })

  app.ports.sendEmarsysData.subscribe(Emarsys)
}

void initCheckout()

const setupStimulus = (): void => {
  application.register("checkout-form", CheckoutFormController)
  application.register("collapsible-items", CollapsibleItemsController)
  application.register("delivery-modal-carousel", DeliveryModalCarousel)
  application.register("delivery-options", DeliveryOptionsController)
  application.register("delivery-options-modal", DeliveryOptionsModalController)
  application.register("delivery-options-modal-trigger", ModalTriggerController)
  application.register("disclosure-item", DisclosureItemController)
  application.register("discount-code", DiscountCodeController)
  application.register("discount-modal", ModalController)
  application.register("discount-modal-trigger", ModalTriggerController)
  application.register("error-tracking", ErrorTrackingController)
  application.register("input-utils", InputUtilsController)
  application.register("input-validity", InputValidityController)
  application.register("evoucher-delivery", EvoucherDeliveryController)
  application.register("gift-message-form", GiftMessageFormController)
  application.register("gift-message-modal", ModalController)
  application.register("gift-message-modal-trigger", ModalTriggerController)
  application.register("gift-message-preview", GiftMessagePreviewController)
  application.register("gift-message-section", GiftMessageSectionController)
  application.register("loader", LoaderController)
  application.register("modal", ModalController)
  application.register("physical-delivery", PhysicalDeliveryController)
  application.register("voucher-amount", VoucherAmountController)
  application.register("voucher-payment-summary", VoucherPaymentSummaryController)
  application.register("voucher-quantity-counter", QuantityCounterController)
  application.register("redirect", RedirectController)
  application.register("window-navigation", WindowNavigationController)
}

const setupTurbo = (): void => {
  const turboSession = Turbo.session as TurboSessionWithDrive
  turboSession.drive = false
}

setupAccordions()
setupTooltips()
setupStimulus()
setupTurbo()

if (window.themeImages) {
  preloadImages(window.themeImages)
}
